import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import SocialProfile from '../SocialProfile';
import { Link } from 'gatsby';
import styled from 'styled-components';
import YouTubePopup from '../../../../../landing-gatsby/src/components/YouTubePopup';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { FooterWrapper, FooterNav, FooterNavItem } from './footer.style';

const VideoLinkText = styled('span')`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #32abdf;
  position: relative;
  display: block;
  cursor: pointer;
  color: #fff;
  transition: 0.15s ease-in-out;
  &:hover {
    color: #32abdf;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 10px;
  }
`;

const VideoLinkWrapper = styled('div')`
  @media screen and (min-width: 1200px) {
    margin-left: 25%;
  }
`;

const LeftColumnWrapper = styled('div')`
  @media screen and (min-width: 1200px) {
    display: flex;
    width: 100%;
  }
`;

const Footer = ({
  row,
  col,
  titleStyle,
  linkStyle,
  copyrightStyle,
  contactItem,
  flexBox,
  contactTitle,
  contactInfo,
  noMargin,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        FOOTER_MENU {
          label
          path
        }
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  const [isVideoOpen, setOpenVideo] = useState(false);

  return (
    <FooterWrapper>
      <YouTubePopup
        videoLink="https://www.youtube.com/watch/MniH4agXPiU"
        isVideoOpen={isVideoOpen}
        setOpenVideo={setOpenVideo}
      />
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...col} {...flexBox}>
            <LeftColumnWrapper>
              <Box {...contactItem}>
                <Text content="Need help?" {...contactTitle} />
                <Obfuscate
                  email="hello@almog.io"
                  style={{
                    ...contactInfo,
                    color: 'white',
                  }}
                ></Obfuscate>
              </Box>
              <VideoLinkWrapper>
                <VideoLinkText onClick={(e) => setOpenVideo(true)}>
                  Company Video
                </VideoLinkText>
                <VideoLinkText
                  onClick={() =>
                    window.open(
                      'https://drive.google.com/file/d/1WLb6rZ3b_no_p-pR9tg6j6H4Jg2gcdSh/view?usp=sharing',
                      '_blank'
                    )
                  }
                  style={{ paddingTop: '10px' }}
                >
                  Company Prezi.
                </VideoLinkText>
              </VideoLinkWrapper>
            </LeftColumnWrapper>

            <Box {...contactItem} style={{ width: '50%' }}>
              <Text content="Find me on" {...contactTitle} />
              <SocialProfile
                className="footer_social"
                items={Data.portfolioJson.SOCIAL_PROFILES}
                iconSize={40}
              />
            </Box>
          </Box>

          <Box {...col} {...flexBox}>
            <FooterNav>
              {Data.portfolioJson.FOOTER_MENU.map((item, index) => (
                <FooterNavItem key={`footer-nav-item-${index}`}>
                  {item.label === 'Why Google Flutter' && (
                    <AniLink
                      to="/why-google-flutter/"
                      target="_self"
                      rel="noreferrer"
                      paintDrip
                      hex="#56AADA"
                    >
                      Why Google Flutter
                    </AniLink>
                  )}
                  {item.label === 'Privacy' && (
                    <Link to="/privacy">Privacy Policy</Link>
                  )}
                  {item.label === 'Terms' && (
                    <Link to="/terms">Terms of Service</Link>
                  )}
                </FooterNavItem>
              ))}
            </FooterNav>
          </Box>
        </Box>

        <Box {...row} {...noMargin}>
          <Box {...col}>
            <Text
              as="span"
              content="©2021 All rights reserved. "
              {...copyrightStyle}
            />
            <a href="https://almog.io">
              <Text as="span" content=" Almog R&D Ltd." {...copyrightStyle} />
            </a>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '70px', '80px', '100px', '10px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 2],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  linkStyle: {
    fontSize: ['22px', '26px', '26px', '30px'],
    color: '#32ABDF',
    mb: 0,
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'space-between',
    // flexWrap: 'wrap'
  },
  contactItem: {
    // width: 1 / 2
  },
  contactTitle: {
    fontSize: ['15x', '15px', '16px', '16px', '16px'],
    mb: '10px',
  },
  contactInfo: {
    fontSize: ['16x', '16px', '18px', '18px', '20px'],
    fontWeight: '500',
    mb: 0,
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
