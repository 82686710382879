import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

const PopupWrapper = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  left: 0;

  animation: ${fadeIn} 1s linear;
`;

const VideoWrapper = styled('div')`
  margin: auto;
`;

const YouTubeIframe = styled('iframe')`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (orientation: landscape) {
    width: 50%;
    height: 50%;
  }

  @media screen and (orientation: portrait) {
    width: 80%;
    height: 50%;
  }

  /*Targets portrait mobile*/
  @media screen and (max-height: 1000px) and (orientation: portrait) {
    width: 100%;
    height: 50%;
  }

  /*Targets landscape mobile*/
  @media screen and (max-width: 1000px) and (orientation: landscape) {
    width: 70%;
    height: 70%;
  }
`;

const YouTubePopup = ({ videoLink, isVideoOpen, setOpenVideo }) => {
  const embeddedLink = videoLink
    ?.replace('/watch/', '/embed/')
    .replace('youtu.be/', 'youtube.com/embed/');
  return (
    <>
      {isVideoOpen && (
        <PopupWrapper onClick={(e) => setOpenVideo(false)}>
          <VideoWrapper>
            <YouTubeIframe
              src={embeddedLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen
              style={{ border: ' 4px solid #56AADA', borderRadius: '12px' }}
            ></YouTubeIframe>
          </VideoWrapper>
        </PopupWrapper>
      )}
    </>
  );
};

export default YouTubePopup;
